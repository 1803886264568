import { UPCOMING_GAMES_LABEL } from 'constants';
import React, { memo, useEffect, useCallback } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Mousewheel } from 'swiper/modules';
import { isEmptyObject } from 'helpers';

import GameDate from 'components/GameDate';
import {
  GameInfoButton,
  Teams,
  TvChannel,
  GameResult,
  LeagueName,
} from 'components';
import { CurrentGameButton } from './components/CurrentGameButton';

import 'swiper/swiper-bundle.css';
import {
  StyledSection,
  LeftSideBar,
  RightSideBar,
  SlideContent,
} from './style';

const Dashboard = ({ games, currentGame, setEventLeague }) => {
  const { data: configuration } = useSelector((state) => state.configuration);
  const [searchParams, setSearchParams] = useSearchParams();
  const params = Object.fromEntries([...searchParams]);

  const isNotSelectionMade =
    configuration.display_leagues_select === '1' && isEmptyObject(params);

  //** FIND event_id FUNCTION TO SET IN URL PARAM **//
  const slideChanged = useCallback(
    (index) => {
      if (!games?.length) return;
      const oneGame = games[index]?.event_id;
      const eventLeagueName = games[index]?.event_league_name;
      if (eventLeagueName) {
        setEventLeague(eventLeagueName);
      }
      if (oneGame && params && params?.league && params?.team) {
        setSearchParams(
          (prevValue) => {
            prevValue.set('eventId', oneGame);
            return prevValue;
          },
          { replace: true }
        );
      }
    },
    [games, params, setSearchParams, setEventLeague]
  );

  useEffect(() => {
    if (!isNotSelectionMade) {
      setSearchParams(
        (prevValue) => {
          prevValue.set('eventId', games[currentGame]?.event_id);
          return prevValue;
        },
        { replace: true }
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isNotSelectionMade]);

  const isUpcomingGames =
    configuration.display_teams_select === '1' &&
    params.team === UPCOMING_GAMES_LABEL;

  //console.dir(isUpcomingGames);

  useEffect(() => {
    if (games?.length && !isUpcomingGames) {
      let event = games[0];
      if (event?.event_league_name) {
        setEventLeague(event.event_league_name);
      }
    }
  }, [isUpcomingGames, games, setEventLeague]);

  return (
    <>
      <LeftSideBar>
        <div className="swiper-button-prev-unique" />
      </LeftSideBar>
      <StyledSection>
        <Swiper
          grabCursor={true}
          id="main-slider"
          initialSlide={currentGame}
          modules={[Navigation, Mousewheel]}
          mousewheel={true}
          navigation={{
            prevEl: '.swiper-button-prev-unique',
            nextEl: '.swiper-button-next-unique',
          }}
          onSlideChange={(swiper) => {
            slideChanged(swiper.activeIndex);
          }}
          slidesPerView={1}
          tag="section"
          wrapperTag="ul"
        >
          {games.map((game, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <SwiperSlide key={`${game.event_id}-${index}`} tag="li">
              <SlideContent>
                <Teams
                  eventType={game?.event_type}
                  location={game.event_location}
                  opponent={
                    isUpcomingGames || isNotSelectionMade
                      ? game.team_b_event_text
                      : game.event_text
                  }
                  team={
                    isUpcomingGames || isNotSelectionMade
                      ? game.team_a_event_text
                      : params?.team
                  }
                />
                {game?.event_league_name && game?.event_league_name != '' ? (
                  <LeagueName leagueName={game?.event_league_name} />
                ) : null}
                {game.stadium_address ? (
                  <GameInfoButton
                    city={game.location_city}
                    date={game.event_start}
                    opponent={game.event_text}
                    region={game.stadium_country}
                    stadium={game.stadium_name}
                    stadiumAddress={game.stadium_address}
                    stadiumLocation={{
                      lat: game?.stadium_lat,
                      lng: game?.stadium_lng,
                    }}
                    team={params?.team}
                    timeZone={game.stadium_time_zone}
                    zipCode={game.stadium_zip_code}
                    eventLeagueName={params?.league}
                  />
                ) : null}
                <GameDate
                  eventState={game.event_state}
                  eventTime={game.event_start}
                  timeZone={game.stadium_time_zone}
                />
                <TvChannel channel={game.event_tv_channel} />
                <GameResult
                  date={game.event_start}
                  eventState={game.event_state}
                  result={game.event_outcome}
                  timeZone={game.stadium_time_zone}
                />
                {!isUpcomingGames ? <CurrentGameButton /> : null}
              </SlideContent>
            </SwiperSlide>
          ))}
        </Swiper>
      </StyledSection>
      <RightSideBar>
        <div className="swiper-button-next-unique" />
      </RightSideBar>{' '}
    </>
  );
};

export default memo(Dashboard);
