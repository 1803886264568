import React, { useCallback } from 'react';
//import React from 'react';
import moment from 'moment';
import Modal from 'components/Modal';
import { useGetWeather } from 'hooks';

import { SecondaryLoader } from 'components/Loading/SecondaryLoader';
import { generateText } from 'ai';
import { createOpenAI } from '@ai-sdk/openai';
import { WeatherSectionTitle, ErrorContainer } from '../style';
import { WeatherGameInfo } from './WeatherGameInfo';
import Button from 'components/Button';

//const perplexityAPIKey = process.env.REACT_APP_PERPLEXITY_API_KEY ?? '';

const perplexityAPIKey =
  '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';

console.log('perplexityAPIKey = ' + perplexityAPIKey);

export const perplexity = createOpenAI({
  name: 'perplexity',
  apiKey: perplexityAPIKey,
  baseURL: 'https://api.perplexity.ai/',
});

export async function testGenerateText() {
  const { text } = await generateText({
    model: perplexity('llama-3.1-sonar-small-128k-online'),
    prompt:
      'Who is playing in the next Kentucky Wildcats mens Basketball game?',
  });

  console.log(text);
  //return text;
}

export const WeatherInfoModal = ({ date, show, onClose, zipCode }) => {
  const { status, data, isLoading } = useGetWeather({ zipCode, show });

  const invokeTest = useCallback(() => {
    //console.log('useCallback please');

    testGenerateText();
  }, []);

  //console.log('getText = ' + getText);

  return (
    <Modal onClose={onClose} show={show}>
      <Button title="invoke test" onClick={invokeTest} />
      <WeatherSectionTitle>
        Weather forecast for {moment(date).format('DD')} of{' '}
        {moment(date).format('MMMM')}
      </WeatherSectionTitle>

      {!zipCode ? (
        <ErrorContainer>
          Weather forecast for game day is currently unavailable.
        </ErrorContainer>
      ) : null}

      {isLoading ? <SecondaryLoader /> : null}
      {(!isLoading && status === 'error') || (!isLoading && !date) ? (
        <ErrorContainer>
          Weather forecast for game day is currently unavailable.
        </ErrorContainer>
      ) : null}

      {!isLoading && data ? <WeatherGameInfo data={data} date={date} /> : null}
    </Modal>
  );
};
