import { Link } from 'react-router-dom';
import styled, { keyframes, css } from 'styled-components';
import { CloseRoundButton } from 'components/Button/style.js';
import { color, device } from 'theme';

const baseIconStyles = css`
  width: 18px;
  height: 18px;
  cursor: pointer;
  transform: scale(0.9);
  transition: transform 0.2s ease-in-out;

  &: hover {
    transform: scale(1);
  }

  & > svg {
    width: 100%;
    height: 100%;
  }
`;

export const GameInfoContainer = styled.div`
  margin-top: 2.5%;
  position: relative;
`;

export const ExpandedMenuContainer = styled.div`
  position: absolute;
  top: 4px;
  left: -108px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: black;
  border-radius: 16px;
  height: 36px;
  width: 252px;
  padding: 0 12px;
  visibility: ${(props) => (props.$isOpen ? 'visible' : 'hidden')};
  transform-origin: center;
  transform: ${(props) => (props.$isOpen ? 'scaleX(1)' : 'scaleX(0)')};
  transition: all 0.2s linear;
  -webkit-box-shadow: 0px 0px 18px 2px rgba(255, 51, 102, 0.64);
  -moz-box-shadow: 0px 0px 18px 2px rgba(255, 51, 102, 0.64);
  box-shadow: 0px 0px 18px 2px rgba(255, 51, 102, 0.64);
`;

export const CenterSpacer = styled.div`
  width: 68px;
  height: 100%;
`;

export const InfoIconContainer = styled.div`
  width: 40px;
  height: 40px;
  cursor: pointer;
  z-index: 2;
  position: relative;
  padding-top: 2px;
`;

export const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 24px;
  height: 24px;

  svg {
    width: 18px;
    height: 18px;
    max-width: 100%;
    max-height: 100%;
    display: block;
    margin: auto;
  }

  /* Specific scaling for larger icons */
  &:first-child svg, /* Ticket */
  &:nth-child(5) svg, /* Map */
  &:last-child svg {
    /* Transport */
    width: 16px;
    height: 16px;
  }
`;

export const StyledLink = styled(Link)`
  ${baseIconStyles}
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
`;

export const GameInfoWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 370px;
  min-height: 450px;
  background: #ffffff;
  border: 1px solid #ccc;
  transition: all 0.5s ease-in-out;
  box-shadow: -2rem 2rem 2rem rgba(0, 0, 0, 0.2);
  padding: 4px;
  border-radius: 5px;
  border: 1px solid #d6d6d6;
  z-index: 100;
  cursor: auto;

  @media ${device.tablet} {
    max-width: unset;
    padding: 5px 10px;
    width: 620px;
  }
`;

export const TeamSection = styled.div`
  width: 100%;
  min-height: 35px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media ${device.tablet} {
    padding-top: 20px;
    justify-content: space-evenly;
    margin-bottom: 20px;
  }
`;

const vsAnimation = keyframes`
  0% {
 transform: rotateY(0);
}
100% {
  transform: rotateY(360deg);
}
`;

export const VsContainer = styled.div`
  font-size: -1rem;
  width: 30px;
  height: 30px;
  margin: 0 10px;
`;

export const VsImg = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  animation-name: ${vsAnimation};
  animation-duration: 5s;
  animation-iteration-count: infinite;
  animation-delay: 1s;
`;

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  background: ${color.red};
  margin: 4px 0;

  @media ${device.tablet} {
    margin: 8px 0;
  }
`;

export const IconsWrapper = styled.div`
  width: 16px;
  height: 16px;
  margin-right: 8px;

  @media ${device.tablet} {
    width: 20px;
    height: 20px;
    margin-right: 16px;
  }
`;

export const InfoSection = styled.div`
  width: 100%;
  height: 30px;
  display: flex;
  align-items: center;
`;

export const WeatherInfoSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 30px;
  margin-bottom: 10px;

  color: ${color.red};
`;

export const WeatherInfoIconSection = styled.div`
  display: flex;
  align-items: center;
`;

export const WeatherIcon = styled.div`
  width: 20px;
  height: 20px;
  margin-right: 10px;
  position: relative;

  @media ${device.tablet} {
    width: 30px;
    height: 30px;
    margin-right: 15px;
  }

  & svg,
  path {
    fill: ${color.red};
  }
`;

export const WeatherSectionTitle = styled.p`
  font-size: 1rem;
  text-align: center;
  margin-bottom: 5px;
  color: ${color.red};
`;

export const WeatherSectionNotification = styled.p`
  font-size: 1rem;
  text-align: center;
  margin-top: 15px;
`;

export const IconImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;

export const AtP = styled.p`
  margin: 0 5px;

  @media ${device.tablet} {
    margin: 0 10px;
  }
`;

export const ErrorContainer = styled.div`
  width: 100%;
  height: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  text-align: center;
  color: ${color.red};

  @media ${device.tablet} {
    font-size: 2rem;
  }
`;

export const CloseBtnWrapper = styled.div`
  height: 2rem;
`;

export const CloseButton = styled(CloseRoundButton)`
  position: absolute;
  top: 0.5rem;
  right: 1rem;

  @media ${device.tablet} {
    right: 0.5rem;
  }
`;

export const Button = styled.button`
  padding: 5px;
  border: 1px solid ${color.red};
  border-radius: 6px;
  color: ${color.red};
  background: transparent;
  cursor: pointer !important;
  transition: all 0.3s ease-in-out;

  &:hover {
    color: #fff;
    background: ${color.red};
  }

  &:disabled {
    opacity: 0.3;
    pointer-events: none;
  }
`;

export const DirectionButton = styled.button`
  color: ${color.red};
  font-size: 1rem;
  cursor: pointer;
  border: none;
  outline: none;
  background: transparent;
  text-align: left;

  &:hover {
    text-decoration: underline;
  }
`;

export const SearchExpediaButton = styled.button`
  color: ${color.red};
  font-size: 1rem;
  cursor: pointer;
  border: none;
  outline: none;
  background: transparent;
  text-align: left;

  &:hover {
    text-decoration: underline;
  }
`;

export const SearchStubhubButton = styled.button`
  color: ${color.red};
  font-size: 1rem;
  cursor: pointer;
  border: none;
  outline: none;
  background: transparent;
  text-align: left;

  &:hover {
    text-decoration: underline;
  }
`;

export const GameInfoLinkContainer = styled.div`
  padding: 20px 5px;
`;

export const WeatherContainer = styled.div`
  padding: 20px 25px;
`;
