import React from 'react';
import Modal from 'components/Modal';
import { GameInfoLinkContainer } from '../style';
import styled from 'styled-components';

const EmptyContainer = styled.div`
  min-height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f5f5f5;
  border-radius: 8px;
  margin: 16px;
`;

export const AdditionalInfo = ({ onClose, show }) => {
  return (
    <Modal onClose={onClose} show={show}>
      <EmptyContainer>Additional information coming soon...</EmptyContainer>
      <GameInfoLinkContainer>
        {/* Future buttons or controls can go here */}
      </GameInfoLinkContainer>
    </Modal>
  );
};
