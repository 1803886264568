import React, { useState, useCallback, useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import moment from 'moment';
import { getAnalytics, logEvent } from 'firebase/analytics';

import {
  InfoIcon,
  MapIcon,
  TicketIcon,
  TransportIcon,
  WeatherIcon,
  AiSearchIcon,
  AdditionalInfoIcon,
} from 'assets/icons';
import { Tooltip } from 'components';
import { GameInfoModal } from './components/GameInfoModal';
import { WeatherInfoModal } from './components/WeatherInfoModal';
import { GameWebSearch } from './components/GameWebSearch';
import { AdditionalInfo } from './components/AdditionalInfo';
import {
  GameInfoContainer,
  InfoIconContainer,
  ExpandedMenuContainer,
  IconContainer,
  StyledLink,
  CenterSpacer,
} from './style';

const SEARCH_TICKET_URL = process.env.REACT_APP_SEARCH_TICKET_URL;
const SEARCH_FLIGHT_URL = process.env.REACT_APP_EXPEDIA_SEARCH_FLIGHT_URL;

export const GameInfoButton = ({
  team,
  opponent,
  date,
  city,
  stadium,
  stadiumLocation,
  zipCode,
  timeZone,
  region,
  eventLeagueName,
}) => {
  // Log google analytics events
  const analytics = getAnalytics();
  const ref = useRef(null);

  const [expandedMenu, setExpandedMenu] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showWeatherModal, setShowWeatherModal] = useState(false);
  const [showWebSearchModal, setShowWebSearchModal] = useState(false);
  const [showAdditionalInfoModal, setShowAdditionalInfoModal] = useState(false);

  const modalHandler = useCallback(() => {
    logEvent(analytics, 'select_content', {
      content_type: 'Show game info',
      content_id: `${team} - ${opponent}`,
    });

    setExpandedMenu((prevState) => !prevState);
  }, [analytics, opponent, team]);

  const clickMapHandler = useCallback(() => {
    setShowModal((prevState) => !prevState);
  }, []);

  const clickWeatherHandler = useCallback(() => {
    setShowWeatherModal((prevState) => !prevState);
  }, []);

  const clickWebSearchHandler = useCallback(() => {
    setShowWebSearchModal((prevState) => !prevState);
  }, []);

  const clickAdditionalInfoHandler = useCallback(() => {
    setShowAdditionalInfoModal((prevState) => !prevState);
  }, []);

  const handleClickOutside = (e) => {
    if (
      expandedMenu &&
      ref.current &&
      !ref.current?.contains(e.target) &&
      !showModal &&
      !showWeatherModal &&
      !showWebSearchModal &&
      !showAdditionalInfoModal
    ) {
      setExpandedMenu(false);
    }
  };

  const isWeatherDisabled = moment(date).isBefore(moment());

  useEffect(() => {
    if (expandedMenu) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  });

  return (
    <GameInfoContainer>
      <InfoIconContainer onClick={modalHandler}>
        <InfoIcon />
      </InfoIconContainer>
      <ExpandedMenuContainer $isOpen={expandedMenu} ref={ref}>
        <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
          <StyledLink target="_blank" to={SEARCH_TICKET_URL}>
            <TicketIcon />
          </StyledLink>

          <IconContainer onClick={clickAdditionalInfoHandler}>
            <AdditionalInfoIcon />
          </IconContainer>

          <IconContainer onClick={clickWebSearchHandler}>
            <AiSearchIcon />
          </IconContainer>
        </div>

        <CenterSpacer />

        <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
          <IconContainer onClick={clickMapHandler}>
            <MapIcon />
          </IconContainer>

          <Tooltip
            isActive={isWeatherDisabled}
            text="Weather forecast is not available for past games"
          >
            <IconContainer
              $isDisabled={isWeatherDisabled}
              onClick={clickWeatherHandler}
            >
              <WeatherIcon />
            </IconContainer>
          </Tooltip>

          <StyledLink target="_blank" to={SEARCH_FLIGHT_URL}>
            <TransportIcon />
          </StyledLink>
        </div>
      </ExpandedMenuContainer>
      {showModal
        ? ReactDOM.createPortal(
            <GameInfoModal
              city={city}
              onClose={clickMapHandler}
              opponent={opponent}
              region={region ? region.toLowerCase() : ''}
              show={showModal}
              stadium={stadium}
              stadiumLocation={stadiumLocation}
              team={team}
              timeZone={timeZone}
              zipCode={zipCode}
            />,
            document.body
          )
        : null}
      {showWeatherModal
        ? ReactDOM.createPortal(
            <WeatherInfoModal
              date={date}
              onClose={clickWeatherHandler}
              show={showWeatherModal}
              zipCode={zipCode}
            />,
            document.body
          )
        : null}
      {showWebSearchModal
        ? ReactDOM.createPortal(
            <GameWebSearch
              onClose={clickWebSearchHandler}
              show={showWebSearchModal}
              team={team}
              opponent={opponent}
              eventLeagueName={eventLeagueName}
            />,
            document.body
          )
        : null}
      {showAdditionalInfoModal
        ? ReactDOM.createPortal(
            <AdditionalInfo
              onClose={clickAdditionalInfoHandler}
              show={showAdditionalInfoModal}
            />,
            document.body
          )
        : null}
    </GameInfoContainer>
  );
};
