import React, { useCallback, useState, useRef, useEffect } from 'react';
import Modal from 'components/Modal';
import styled from 'styled-components';
import { generateText } from 'ai';
import { createOpenAI } from '@ai-sdk/openai';
import Button from 'components/Button';
import { color } from 'theme';

const ChatContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 400px;
  padding: 48px 12px 12px 12px;
  margin: 0;
`;

const ChatHistory = styled.div`
  flex-grow: 1;
  overflow-y: auto;
  margin-bottom: 12px;
  padding: 16px;
  border-radius: 12px;
  background: rgba(0, 0, 0, 0.9);
  min-height: 0;

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background: rgba(255, 255, 255, 0.1);
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background: rgba(255, 255, 255, 0.2);
    border-radius: 4px;
  }
`;

const Message = styled.div`
  margin-bottom: 24px;
  line-height: 1.4;

  &.user {
    color: ${color.greyText};
  }

  &.ai {
    color: ${color.white};
    margin-bottom: 32px;
  }
`;

const InputContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  height: 80px;
  margin-top: auto;
  padding-top: 8px;
`;

const InputWrapper = styled.div`
  position: relative;
  flex-grow: 1;
  height: 60px;
  display: flex;
  align-items: center;
`;

const Input = styled.textarea`
  flex-grow: 1;
  background: rgba(0, 0, 0, 0.9);
  border: none;
  border-radius: 12px;
  padding: 12px 32px 12px 12px;
  color: ${color.white};
  font-size: 14px;
  resize: none;
  height: 100%;
  width: 100%;

  &:focus {
    outline: none;
  }

  &::placeholder {
    color: rgba(255, 255, 255, 0.5);
  }
`;

const ClearButton = styled.button`
  position: absolute;
  right: 8px;
  top: 50%;
  transform: translateY(-50%);
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: ${color.greyText};
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  opacity: 0.7;

  &:hover {
    opacity: 1;
  }

  &::before,
  &::after {
    content: '';
    position: absolute;
    width: 10px;
    height: 2px;
    background: black;
    transform: rotate(45deg);
  }

  &::after {
    transform: rotate(-45deg);
  }
`;

const ActionButton = styled(Button)`
  height: 40px;
  min-width: 60px;
  padding: 8px 16px;
  background: #ff3366;
  border: none;
`;

const perplexityAPIKey =
  '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';

export const perplexity = createOpenAI({
  name: 'perplexity',
  apiKey: perplexityAPIKey,
  baseURL: 'https://api.perplexity.ai/',
});

const processMarkdown = (text) => {
  return text.split(/(\*\*.*?\*\*)/).map((part, index) => {
    if (part.startsWith('**') && part.endsWith('**')) {
      return <strong key={index}>{part.slice(2, -2)}</strong>;
    }
    return part;
  });
};

// List of question templates - easily extendable
const questionTemplates = [
  (team, opponent, league) =>
    `What was the score the last time ${team} played ${opponent} (${league})`,
  (team, opponent, league) =>
    `What is the all-time record between ${team} and ${opponent} (${league})`,
  (team, opponent, league) =>
    `Who are the head coaches for the ${team} and ${opponent} (${league})`,
];

export const GameWebSearch = ({
  onClose,
  show,
  team,
  opponent,
  eventLeagueName,
}) => {
  const [messages, setMessages] = useState([]);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);

  // Get next question in sequence or empty string if all used
  const getNextQuestion = () => {
    if (!team || !opponent) return '';

    if (currentQuestionIndex >= questionTemplates.length) return '';

    return questionTemplates[currentQuestionIndex](
      team,
      opponent,
      eventLeagueName || ''
    );
  };

  const [inputText, setInputText] = useState(getNextQuestion());
  const chatHistoryRef = useRef(null);

  const scrollToBottom = () => {
    if (chatHistoryRef.current) {
      chatHistoryRef.current.scrollTop = chatHistoryRef.current.scrollHeight;
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const generateResponse = useCallback(async () => {
    if (messages.length >= 20) {
      // 10 questions and 10 responses
      alert('You have reached the maximum number of questions (10).');
      return;
    }

    const question = inputText.trim();
    if (!question) return;

    // Add user message
    setMessages((prev) => [...prev, { type: 'user', text: question }]);
    setInputText('');

    try {
      const { text } = await generateText({
        model: perplexity('llama-3.1-sonar-small-128k-online'),
        prompt: question,
      });

      // Add AI response and increment to next question
      setMessages((prev) => [...prev, { type: 'ai', text }]);
      setCurrentQuestionIndex((prev) => prev + 1);
      setInputText(getNextQuestion());
    } catch (error) {
      console.error('Error generating response:', error);
      setMessages((prev) => [
        ...prev,
        {
          type: 'ai',
          text: 'Sorry, I encountered an error processing your request.',
        },
      ]);
    }
  }, [inputText, messages.length, team, opponent, currentQuestionIndex]);

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      generateResponse();
    }
  };

  return (
    <Modal onClose={onClose} show={show}>
      <ChatContainer>
        <ChatHistory ref={chatHistoryRef}>
          {messages.map((message, index) => (
            <Message key={index} className={message.type}>
              {processMarkdown(message.text)}
            </Message>
          ))}
        </ChatHistory>
        <InputContainer>
          <InputWrapper>
            <Input
              value={inputText}
              onChange={(e) => setInputText(e.target.value)}
              onKeyPress={handleKeyPress}
              placeholder="Ask a question..."
              spellCheck="false"
            />
            {inputText && (
              <ClearButton
                onClick={() => setInputText('')}
                aria-label="Clear input"
              />
            )}
          </InputWrapper>
          <ActionButton title="Go" onClick={generateResponse} />
        </InputContainer>
      </ChatContainer>
    </Modal>
  );
};
